"use client";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import { CiMenuBurger } from "react-icons/ci";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();
  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };
  return (
    <div>
      <nav className="uppercase fixed top-0 right-0 left-0 z-[500] tracking-widest transition-all duration-1000 backdrop-blur-sm bg-black bg-opacity-50 ">
        <div className="lg:hidden h-max w-screen flex justify-between items-center p-3">
          <Link href="#home">
            <Image src="/LOGO.png" alt="LOGO" width={0} height={0} sizes="100vw" loading="eager" className=" h-10 md:h-12 w-max cursor-pointer" />
          </Link>
          <button onClick={toggleMenu} aria-label="menubutton" className="w-max text-white h-max">
            <CiMenuBurger className=" h-7 w-10 " />
          </button>
        </div>
        <div ref={menuRef} id="menu" className={`lg:hidden ${isMenuOpen ? "flex" : "hidden"} flex-col justify-between w-full text-lg gap-2 p-4 `}>
          <Link href="#home" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "/" ? "text-blue-500" : "text-white"}  brightness-200 flex items-center transition-all duration-200`} onClick={toggleMenu}>
            Home
          </Link>
          <Link href="#about" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "/about" ? "text-blue-500" : "text-white"}  brightness-200 flex items-center transition-all duration-200`} onClick={toggleMenu}>
            About
          </Link>
          {/* <Link href="/services" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname.startsWith("/services") ? "text-blue-500" :"text-white"}  brightness-200 flex items-center transition-all duration-200`} onClick={toggleMenu}>
          Services
        </Link> */}
          <Link href="#product" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "/#product" ? "text-blue-500" : "text-white"}  brightness-200 flex items-center transition-all duration-200`} onClick={toggleMenu}>
            Products
          </Link>
          <Link href="#contact" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "/contact" ? "text-blue-500" : "text-white"}  brightness-200 flex items-center transition-all duration-200`} onClick={toggleMenu}>
            Contact
          </Link>
        </div>
        <div className="hidden lg:flex absolute flex-row items-center justify-between  w-screen z-50 backdrop-blur-sm bg-black bg-opacity-40">
          <Link href="#home" className="cursor-pointer text-center ml-10 flex flex-col gap-2 p-4 ">
            <Image src="/LOGO.png" alt="LOGO" width={0} height={0} sizes="100vw" className=" h-12 w-max" />
          </Link>
          <div className="flex flex-row gpa-3 justify-between min-w-[600px] xl:min-w-[1000px] max-w-full px-[5%] md:px-[10%]">
            <Link href="#home" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "/#home" ? "text-blue-500" : "text-white"} text-lg brightness-200 flex items-center transition-all duration-200`}>
              Home
            </Link>
            <Link href="#about" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "#about" ? "text-blue-500" : "text-white"} text-lg brightness-200 flex items-center transition-all duration-200 `}>
              About
            </Link>
            {/* <Link href="/about" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "/about" ? "text-blue-500" :"text-white"} text-lg brightness-200 flex items-center transition-all duration-200 `}>
              Service
            </Link> */}
            <Link href="#product" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "/#product" ? "text-blue-500" : "text-white"} text-lg brightness-200 flex items-center transition-all duration-200 `}>
              products
            </Link>
            {/* <div className="group relative">
              <Link href="/services" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname.startsWith("/services") ? "text-blue-500" :"text-white"}  brightness-200 flex items-center transition-all duration-200`} onClick={toggleMenu}>
                Services <SlArrowDown className="text-xs hidden xl:block" />
              </Link>
              <div className=" hidden group-hover:flex flex-col  gap-4 absolute top-full left-0 bg-primary text-xs w-[14vw] py-6 px-2 shadow-lg">
                {providedservices.map((s, index) => (
                  <Link key={index} href={`/services/${s.toLowerCase().replace(/\s+/g, "")}`} className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname === `/services/${s.toLowerCase().replace(/\s+/g, "")}` ? "text-blue-500" :"text-white"} brightness-200 transition-all duration-200`}>
                    {s}
                  </Link>
                ))}
              </div>
            </div> */}
            {/* <div className="group relative">
              <Link href="/projects" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname.startsWith("/projects") ? "text-blue-500" :"text-white"}  brightness-200 flex items-center transition-all duration-200 `}>
                Projects <SlArrowDown className="text-xs hidden xl:block" />
              </Link>

              <div className=" hidden group-hover:flex flex-col  gap-4 absolute top-full left-0 bg-primary text-xs w-[14vw] py-6 px-2 shadow-lg">
                {projects.map((p, index) => (
                  <Link key={index} href={`/projects/${p.toLowerCase().replace(/\s+/g, "")}`} className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname === `/projects/${p.toLowerCase().replace(/\s+/g, "")}` ? "text-blue-500" :"text-white"} brightness-200 transition-all duration-200`}>
                    {p}
                  </Link>
                ))}
              </div>
            </div> */}
            <Link href="#contact" className={`hover:text-blue-500 lg:hover:scale-125 duration-300 ${pathname == "#contact" ? "text-blue-500" : "text-white"} text-lg brightness-200 flex items-center transition-all duration-200 `}>
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
